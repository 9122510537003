export const lastFmApiKey = "f715c404aefe9e2c48de311ed67f9b13";
export const apiRoot = "https://ws.audioscrobbler.com/2.0";
export const apiRootBase = `https://ws.audioscrobbler.com/2.0?api_key=${lastFmApiKey}&format=json`;

export function checkLogin(): boolean {
  return true;
}

export function getUser(): string | null {
  return localStorage.getItem("name");
}

export function isLoggedIn(): boolean {
  return getSession() !== null;
}

export function getSession(): string | null {
  return localStorage.getItem("session");
}

export function getProfilePicture(): string | null {
  return localStorage.getItem("profilePicture");
}

export function logOut() {
  localStorage.removeItem("session");
  localStorage.removeItem("name");
  localStorage.removeItem("profilePicture");
}
