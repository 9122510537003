import ScrobbleForm from "./ScrobbleForm";
import LastFmLogin from "./LastFmLogin";
import AppFooter from "./AppFooter";
import './App.css'
import * as api from './LastFmApi';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container, Link, Typography } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Stack } from "@mui/system";

// Roboto font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#9d00ff",
    },
  },
});

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="content">
          <Container className="App" maxWidth={"xs"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={4}
              mb={2}
            >
              <Typography variant="h4">
                s.crobble.it
              </Typography>
              <LastFmLogin />
            </Stack>
            <Typography variant={"body1"} mb={2}>A simple but effective <Link href={"https://last.fm/"} target={"_blank"} underline={"hover"}>Last.fm</Link> scrobbler.</Typography>
            <ScrobbleForm />
          </Container>
        </div>
        <AppFooter />
      </ThemeProvider>
    </LocalizationProvider>
  );
}
