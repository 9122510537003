import React from "react";

import {
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

type DatePickerProps = {
  label: string;
  onDateChange: (newValue: Date | null) => void;
  date: Date | null;
};

type DatePickerState = {};
class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
  constructor(props: DatePickerProps) {
    super(props);
    this.state = {};

    this.buttonGroupChanged = this.buttonGroupChanged.bind(this);
  }

  buttonGroupChanged(_: any, newChoice: string) {
    if (typeof newChoice !== "string") return;
    this.props.onDateChange(newChoice === "custom" ? new Date() : null);
  }

  render() {
    return (
      <>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Typography variant="body1">{this.props.label}</Typography>
          <ToggleButtonGroup
            exclusive
            value={this.props.date !== null ? "custom" : "now"}
            onChange={this.buttonGroupChanged}
          >
            <ToggleButton value="now">Now</ToggleButton>
            <ToggleButton value="custom">Custom</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        {this.props.date !== null && (
          <DateTimePicker
            renderInput={(props) => (
              <TextField {...props} onKeyDown={(e) => e.preventDefault()} />
            )}
            label="Scrobble Date & Time"
            onChange={(newValue) => {
              this.props.onDateChange(newValue);
            }}
            disabled={this.props.date === null}
            value={this.props.date}
          />
        )}
      </>
    );
  }
}

export default DatePicker;
