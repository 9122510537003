import { Autocomplete, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";

const API_KEY = "f715c404aefe9e2c48de311ed67f9b13";
const API_URL = "https://ws.audioscrobbler.com/2.0/";

const search = async (
  method: string,
  queryName: string,
  queryValue: string,
  listPath: string,
  innerPath: string,
  extraQuery?: string
) => {
  return fetch(
    `${API_URL}?api_key=${API_KEY}&format=json&method=${method}&${queryName}=${queryValue}${
      extraQuery ?? ""
    }`
  )
    .then((it) => it.json())
    .then((it) => {
      let res: string[] = [];
      _.get(it, listPath).forEach((entry: any) => {
        const val = _.get(entry, innerPath);
        if (!res.includes(val)) {
          res.push(val);
        }
      });

      return res;
    });
};

export default function LastFmAutocomplete(props: {
  label: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  value: string;
  onChange: (newValue: string) => void;
  method: string;
  queryName: string;
  listPath: string;
  innerPath: string;
  extraQuery?: string;
}) {
  const [results, setResults] = useState<string[]>([]);
  const [selected, setSelected] = useState<null | string>("");
  const [debounce, setDebounce] = useState<number>(-1);

  useEffect(() => {
    if (props.value.trim() === "") {
      setResults([]);
      setSelected(null);
      return;
    }

    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        search(
          props.method,
          props.queryName,
          props.value,
          props.listPath,
          props.innerPath,
          props.extraQuery
        ).then((res) => setResults(res));
      }, 500) as any as number
    );
  }, [props.value]);

  return (
    <Autocomplete
      freeSolo
      options={results}
      value={selected}
      onChange={(_, newValue: string | null) => setSelected(newValue)}
      inputValue={props.value}
      onInputChange={(_, newValue: string) => props.onChange(newValue)}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={props.label}
          required={props.required}
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
}
