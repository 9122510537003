import { Box, Divider, Link, Stack, Typography } from "@mui/material";

export default function AppFooter() {
  return (
    <footer id="footer">
      <Box
        pt={2}
        pb={2}
        mt={4}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
        >
          <Typography variant="body2">s.crobble.it</Typography>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Link
              color="grey.500"
              href="https://www.last.fm/user/Nowaha"
              target="_blank"
              underline="hover"
              variant="body2"
            >
              by nowaha
            </Link>
            <Divider flexItem orientation="vertical" />
            <Link
              color="grey.500"
              href="https://gist.github.com/Nowaha/6fc6db18080f9d36f3b7d578ea049a83"
              target="_blank"
              underline="hover"
              variant="body2"
            >
              privacy policy
            </Link>
            <Divider flexItem orientation="vertical" />
            <Link color="grey.500" href="https://github.com/Nowaha" target="_blank" underline="hover" variant="body2">
              source (tba)
            </Link>
          </Stack>
        </Stack>
      </Box>
    </footer>
  );
}
