import { ChangeEvent, useState } from "react";
import "./ScrobbleForm.css";
import * as api from "./LastFmApi";
import DatePicker from "./DatePicker";
import {
  Alert,
  Button,
  Collapse,
  Stack,
  CircularProgress,
  Box,
} from "@mui/material";

import { PinnableTextField } from "./PinnableTextField";
import moment from "moment";
import { AlertColor } from "@mui/material/Alert/Alert";
import LastFmAutocomplete from "./LastFmAutocomplete";

export default function ScrobbleForm() {
  const [songName, setSongName] = useState("");
  const [songNameError, setSongNameError] = useState<string | null>(null);
  const [artistName, setArtistName] = useState("");
  const [artistNameError, setArtistNameError] = useState<string | null>(null);
  const [albumName, setAlbumName] = useState("");
  const [albumArtistName, setAlbumArtistName] = useState("");
  const [date, setDate] = useState<Date | null>(null);

  const [artistNamePinned, setArtistNamePinned] = useState(false);
  const [songNamePinned, setSongNamePinned] = useState(false);
  const [albumNamePinned, setAlbumNamePinned] = useState(false);
  const [albumArtistNamePinned, setAlbumArtistNamePinned] = useState(false);

  const [alertContent, setAlertContent] = useState<string | null>(null);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");

  const [fetching, setFetching] = useState(false);

  function formSubmission() {
    if (fetching) return;
    setFetching(true);
    setAlertContent(null);

    let valid = true;
    if (artistName === "") {
      setArtistNameError("Required");
      valid = false;
    }

    if (songName === "") {
      setSongNameError("Required");
      valid = false;
    }

    if (valid) {
      if (!api.isLoggedIn()) {
        setFetching(false);
        setAlertSeverity("error");
        setAlertContent("You have to be logged in to scrobble.");
        return;
      }

      let toSend: any = {
        session: api.getSession(),
        artist: artistName,
        track: songName,
      };
      if (date == null) {
        toSend = { ...toSend, timestamp: moment().unix() };
      } else {
        toSend = { ...toSend, timestamp: moment(date.toISOString()).unix() };
      }

      if (albumName !== "") toSend = { ...toSend, album: albumName };
      if (albumArtistName !== "")
        toSend = { ...toSend, albumArtist: albumArtistName };
      fetch("https://s.crobble.it:3333/scrobble", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toSend),
      })
        .then((it) => it.json())
        .then((it) => {
          if (it.hasOwnProperty("error")) {
            if (it["error"] === 9) {
              api.logOut();
              setFetching(false);
              setAlertSeverity("error");
              setAlertContent("Your session expired. Re-log in and try again.");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              return;
            }
            scrobbleFailure();
          } else {
            successfulScrobble();
          }
        })
        .catch(scrobbleFailure);
    } else {
      setFetching(false);
    }
  }

  function successfulScrobble() {
    setFetching(false);
    setAlertSeverity("success");
    setAlertContent(`Scrobble of '${artistName} - ${songName}' registered!`);
    clearButtonClick();
  }

  function scrobbleFailure() {
    setFetching(false);
    setAlertSeverity("error");
    setAlertContent("Scrobble failed, please try again.");
  }

  function artistNameChange(newValue: string | null) {
    setArtistName(newValue ?? "");
    setArtistNameError(null);
    setAlertContent(null);
  }

  function songNameChange(newValue: string | null) {
    setSongName(newValue ?? "");
    setSongNameError(null);
    setAlertContent(null);
  }

  function clearButtonClick() {
    if (!songNamePinned) {
      setSongName("");
      setSongNameError(null);
    }
    if (!artistNamePinned) {
      setArtistName("");
      setArtistNameError(null);
    }
    if (!albumNamePinned) setAlbumName("");
    if (!albumArtistNamePinned) setAlbumArtistName("");
    setDate(null);
  }

  return (
    <div className="scrobble-info-form">
      <Stack
        spacing={2}
        direction={"column"}
        alignItems={"stretch"}
        justifyContent={"flex-start"}
      >
        <Collapse in={alertContent !== null}>
          <Alert variant="outlined" severity={alertSeverity}>
            {alertContent}
          </Alert>
        </Collapse>

        <LastFmAutocomplete
          required={true}
          value={artistName}
          label={"Artist Name"}
          onChange={artistNameChange}
          error={artistNameError !== null}
          helperText={artistNameError ?? undefined}
          method={"artist.search"}
          queryName={"artist"}
          listPath={"results.artistmatches.artist"}
          innerPath={"name"}
        />

        <LastFmAutocomplete
          required={true}
          value={songName}
          label={"Song Name"}
          onChange={songNameChange}
          error={songNameError !== null}
          helperText={songNameError ?? undefined}
          method={"track.search"}
          queryName={"track"}
          listPath={"results.trackmatches.track"}
          innerPath={"name"}
          extraQuery={artistName !== null ? `&artist=${artistName}` : undefined}
        />

        <LastFmAutocomplete
          value={albumName}
          label={"Album Name"}
          onChange={(newValue: string) => {
            setAlbumName(newValue);
            setAlertContent(null);
          }}
          method={"album.search"}
          queryName={"album"}
          listPath={"results.albummatches.album"}
          innerPath={"name"}
        />

        <LastFmAutocomplete
          value={albumArtistName}
          label={"Album Artist"}
          onChange={(newValue: string) => {
            setAlbumArtistName(newValue);
            setAlertContent(null);
          }}
          method={"artist.search"}
          queryName={"artist"}
          listPath={"results.artistmatches.artist"}
          innerPath={"name"}
        />

        <DatePicker
          label="Scrobble Date"
          onDateChange={(newDate) => setDate(newDate)}
          date={date}
        />
      </Stack>

      <Stack
        className="scrobble-form-actions"
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        mt={4}
      >
        <Button
          className="scrobble-action-button clear-button"
          onClick={clearButtonClick}
          variant={"text"}
        >
          Clear
        </Button>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {fetching && <CircularProgress size={24} />}
          <Button
            className="scrobble-action-button scrobble-button"
            onClick={formSubmission}
            variant={"contained"}
          >
            Scrobble
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
