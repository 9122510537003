import React, { useEffect, useState } from "react";
import { Stack, Typography, Menu, MenuItem } from "@mui/material";
import lastFmLogo from "./img/lastfm_logo.png";
import "./LastFmLogin.css";
import * as api from "./LastFmApi";
import { useForceUpdate } from "./Utils";

const notLoggedInText = "Not logged in";

export default function LastFmLogin() {
  const forceUpdate = useForceUpdate();
  const [usernameText, setUsernameText] = useState(notLoggedInText);
  const [profilePicture, setProfilePicture] = useState(
    api.getProfilePicture() ?? lastFmLogo
  );
  const [profilePictureLoadingState, setProfilePictureLoadingState] =
    useState(false);
  const [popoverShown, setPopoverShown] = useState(false);
  let popOverAnchorRef = React.useRef<Element>();

  function profileSectionClick() {
    if (!api.isLoggedIn()) {
      lastFmLogin();
    } else {
      setPopoverShown(true);
    }
  }

  function lastFmLogin() {
    window.location.href = `https://www.last.fm/api/auth/?api_key=${api.lastFmApiKey}&cb=${window.location.protocol}//${window.location.host}`;
  }

  function logOut() {
    api.logOut();
    setProfilePicture(lastFmLogo);
  }

  function checkLoginCallback(): boolean {
    if (!api.isLoggedIn() && window.location.href.includes("?token=")) {
      let token = window.location.href.split("?token=")[1];
      window.history.pushState({}, "", window.location.href.split("?")[0]);

      fetch(`https://s.crobble.it:3333/auth?token=${token}`)
        .then((it) => it.json())
        .then((it) => {
          localStorage.setItem("session", it.session.key);
          localStorage.setItem("name", it.session.name);
          loadProfilePicture();
        })
        .then(() => forceUpdate());

      return true;
    }

    return false;
  }

  function loadProfilePicture() {
    if (profilePictureLoadingState) return;
    setProfilePictureLoadingState(true);

    try {
      fetch(`${api.apiRootBase}&method=user.getInfo&user=${api.getUser()}`)
        .then((it) => it.json())
        .then((it) => {
          let res = it.user.image[0]["#text"] ?? lastFmLogo;
          localStorage.setItem("profilePicture", res);
          setProfilePicture(res);
        });
    } catch {
      logOut();
    }
  }

  useEffect(() => {
    checkLoginCallback();

    if (api.isLoggedIn()) {
      if (usernameText === notLoggedInText) {
        setUsernameText(api.getUser() ?? "Error!");
      }
      if (api.getProfilePicture() == null) {
        loadProfilePicture();
      }
    }
    return () => {};
  });

  const loggedIn = api.isLoggedIn();
  return (
    <>
      <Stack
        aria-describedby={"lastfm-popover"}
        direction={"row"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          profileSectionClick();
        }}
        ref={popOverAnchorRef}
      >
        <Typography
          id="login-caption"
          className={!loggedIn ? "not-logged-in" : undefined}
          variant={"caption"}
          mr={1}
        >
          {usernameText}
        </Typography>
        <img
          id="last-fm-logo"
          src={profilePicture}
          alt="Last.fm"
          width={"32px"}
        />
      </Stack>
      <Menu
        open={popoverShown}
        onClose={() => setPopoverShown(false)}
        anchorEl={popOverAnchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            window.open(
              `https://www.last.fm/user/${api.getUser()}`,
              "about:blank"
            );
            setPopoverShown(false);
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            api.logOut();
            setPopoverShown(false);
            window.location.reload();
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}
